<template>
  <v-form>
    <v-container>

      <!--Dialog para los errores-->
      <v-dialog v-model="msgbox" persistent width="600px">
        <v-card>
          <v-card-title class="headline" v-text="msgtitulo"></v-card-title>
          <v-card-text class="text--primary" color="black" v-text="msgtexto"></v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="success" text @click="cerrar_mensaje">Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!---->

      <v-toolbar>
        <v-toolbar-title>Datos del Profesor</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
      </v-toolbar>

      <v-card style="padding:10px">  
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-img
              height="200"
              width="200"
              :src="profesorData.foto_url"
            ></v-img>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-row
                no-gutters
                style="height: 85px;"
              >
              <v-col
                cols="12"
                sm="6"
                md="12"
                >
                <v-text-field
                  readonly
                  v-model="profesorData.nombre"
                  label="Nombre"
                  outlined
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="6"
                md="4"
                >
                
              </v-col>              
            </v-row>

            <v-row>
              <v-col> 
                <v-text-field
                  readonly
                  v-model="horas_dictadas_x_curso"
                  label="Horas Dictadas"
                  outlined
                ></v-text-field>
              </v-col> 
              <v-col> 
                <v-text-field
                  readonly
                  v-model="cursos_profesor.length"
                  label="Cursos Dictados"
                  outlined
                ></v-text-field>
              </v-col> 
            </v-row>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <h4 class="text-center">CALIFICACIÓN</h4>
            <v-row align="center"
              justify="center">
              <v-col
                  cols="12"
                  sm="6"
                  md="8"
                >
                <v-rating
                  v-model="rating"
                  background-color="gray lighten-3"
                  color="gray"
                  large
                  @input="changeRating"
                ></v-rating>
              </v-col>

              <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                <span style="font-size:20px" class="text-center">{{rating}}</span>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                <p style="font-size:20px" class="text-center">Comentarios: {{data_comentarios.length}}</p>
              </v-col>
              <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                <v-btn class="ml-1" height="64%" color="success" @click="dialog_comentarios = true">Comentarios</v-btn>
              </v-col>
            </v-row> -->
          </v-col>
        </v-row>
      </v-card>

      <!--  ALERTAS DE COMENTARIO ENVIADO -->
      <v-alert
        class="mt-2"
        :value="alertRating"
        color="pink"
        dark
        border="top"
        icon="mdi-home"
        transition="scale-transition"
      >
      Su calificación se ha registrado. ¡ Muchas gracias !
      </v-alert>

      <v-row justify="center">
        <v-col class="shrink">
          <v-btn
              class="ma-2"
              color="success"
              @click="expand = !expand"
            >
              Información del Tutor
            </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-card v-show="expand" class="mx-auto">
          <v-card class="mt-2" style="padding:10px">
             <v-row align="center"
                  justify="center">
              <v-col  >
                <iframe class="Sobre_tutor" :src="profesorData.video_url">
                </iframe>
              </v-col>
              <v-col cols="12" sm="6" md="5">
                <v-row class="el_tutor">
                  <h2> Sobre el Tutor</h2>
                  <v-spacer></v-spacer>
                  <v-btn class="ml-1" large color="success" @click="dialog_datos_profesor = true">VER DATOS</v-btn>
                  <v-spacer></v-spacer>
                </v-row>
                </br>
                <h3>{{profesorData.nombre}} {{profesorData.apellido}}</h3>
                <h5>{{profesorData.carrera}}</h5>
                </br>
                <p>{{profesorData.descripcion}}</p>
                <a target="_blank" :href="'https://api.whatsapp.com/send/?phone=51'+profesorData.celular+'&text=Hola '+profesorData.nombre+', he visto tu perfil de tutor en LearnIT y quisiera más información, el curso que me dificulta es '+curso.nombre" class="text-center"><img width="30" height="30" src="../../assets/whatsapp.png"/>
                <span style="vertical-align: super;"> Habla por wpp con el tutor!!</span></a>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </v-expand-transition>

      <v-divider
        class="mx-4"
        vertical
      ></v-divider>
      
      <!-- DIALOGO MOSTRAR COMENTARIOS Y COMENTAR -->
      <v-dialog v-model="dialog_comentarios" max-width="1000px" max-height="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Comentarios para el tutor</span>
            </v-card-title>
             <div style="width: 400px; margin-left:50px">
              <v-progress-linear
                :value="starts5"
                color="green"
                height="25"
                
              ><span style="color:white">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                </span></v-progress-linear>

              <br>

              <v-progress-linear
                :value="starts4"
                color="blue-grey"
                height="25"
              ><span style="color:white">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
              </span>
              </v-progress-linear>

              <br>

              <v-progress-linear
                color="orange"
                :value="starts3"
                height="25"
              ><span style="color:white">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
                </span>
              </v-progress-linear>

              
              <br>

              <v-progress-linear
                :value="starts2"
                color="red"
                height="25"
              ><span style="color:white">
                    <img width="20" height="20" src="../../assets/star.png">
                    <img width="20" height="20" src="../../assets/star.png">
              </span>
              </v-progress-linear>

              
              <br>

              <v-progress-linear
                :value="starts1"
                color="pink"
                height="25"
              ><span style="color:white">
                <img width="20" height="20" src="../../assets/star.png">
                
                    </span>
              </v-progress-linear>
            </div>


            <v-card-text>
              <v-container>
                <v-row v-for="(item, i) in data_comentarios" :key="i">
                  <v-col cols="12" sm="6" md="1">
                    <v-img
                      max-height="20"
                      max-width="20"
                      src="../../assets/profile.png"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" sm="6" md="11">
                    <h3>{{item.nombre}} {{item.apellido}} </h3><span>    {{item.fecha}}</span>
                    <p>{{item.mensaje}}</p>                  
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>


            <v-container>
                <v-row>
                  <v-col cols="12" sm="2" md="12">
                    <h4>DEJANOS TU COMENTARIO</h4>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-textarea
                      filled
                      label="COMENTA"
                      auto-grow
                      v-model="comentarios"
                    ></v-textarea>
                  </v-col>
                  <!--  ALERTAS DE COMENTARIO ENVIADO -->
                  <v-col cols="12" sm="6" md="12">
                    <v-alert
                      class="mt-2"
                      :value="alertComentarios"
                      color="pink"
                      dark
                      border="top"
                      icon="mdi-home"
                      transition="scale-transition"
                    >
                      Su comentario se ha registrado. ¡ Muchas gracias !
                    </v-alert>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-btn class="ml-1" height="30px" color="success" @click="guardarComentario">Enviar</v-btn>
                  </v-col>
                </v-row>
            </v-container>
            
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="dialog_comentarios = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <!-- DIALOGO MOSTRAR DATOS DEL PROFESOR -->
      <v-dialog v-model="dialog_datos_profesor" max-width="600px" max-height="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Datos el Tutor</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field readonly v-model="profesorData.nombre" label="Nombre"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field readonly v-model="profesorData.apellido" label="Apellido"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field readonly v-model="profesorData.universidad" label="Universidad"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field readonly v-model="profesorData.carrera" label="Carrera"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field readonly v-model="profesorData.ciclo_actual" label="Ciclo Actual"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field readonly v-model="profesorData.nacimiento" label="Nacimiento"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="dialog_datos_profesor = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      <!--Escoger Curso-->
      <v-dialog v-model="cursodialog" width="1200px">
        <v-data-table :headers="cursos_headers" :items="cursos_profesor" :search="curso_search" class="elevation-1 ">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Cursos Profesor</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field v-model="curso_search" label="Buscar Curso" single-line hide-details></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn  color="error" @click="methodagregarcurso(item)">Escoger</v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn  color="error" text >No hay Cursos registrados</v-btn>
          </template>
        </v-data-table>
      </v-dialog>

      <!--Escoger Curso-->
      <v-dialog v-model="dialog_asesorias.show" width="1200px">
        <v-data-table :headers="dialog_asesorias.headers" :items="dialog_asesorias.asesorias" :search="dialog_asesorias.search" class="elevation-1" >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Asesorías abiertas del tutor</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field v-model="dialog_asesorias.search" label="Buscar asesoría" single-line hide-details></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.fecha="{ item }">
            {{ formatDate(new Date(item.fecha)) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn color="success" link target="_blank" :to="'/alumno/unirse_asesoria/' + item.id">Unirse asesoría</v-btn>
          </template>
          <template v-slot:no-data>
            <v-btn color="error" text >No se encontraron asesorías</v-btn>
          </template>
        </v-data-table>
      </v-dialog>

      <v-toolbar height="auto" class="py-3 mb-4">
        <div class="d-flex flex-column flex-md-row justify-center justify-md-space-between align-center w-100">
          <div class="d-flex align-center">
            <v-toolbar-title>
              Cursos del Tutor
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
          </div>
          <div class="text-center mt-2 mt-md-0">
            <div class="d-md-none">
            <v-btn-group class="d-block mx-auto mb-2">
              <v-btn color="success" @click="cursodialog = true">Ver Cursos y Precios</v-btn>
              <v-btn color="success" class="mt-2 mt-sm-0 ml-sm-2" @click="dialog_asesorias.show = true">Asesorías abiertas del tutor</v-btn>
            </v-btn-group>
            </div>
            <div class="d-none d-md-block">
              <v-btn color="success" @click="cursodialog = true">Ver Cursos y Precios</v-btn>
              <v-btn color="success" class="mt-2 mt-sm-0 ml-sm-2" @click="dialog_asesorias.show = true">Asesorías abiertas del tutor</v-btn>
            </div>
          </div >
        </div>
      </v-toolbar>

      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="curso.nombre"
            label="Curso Seleccionado"
            readonly
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="curso.precio_individual"
            label="Precio Individual"
            readonly
            prefix="S/."
            persistent-hint
            hint="Precio por hora de Asesoria Individual"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="curso.precio_grupal"
            label="Precio Grupal"
            readonly
            prefix="S/."
            persistent-hint
            hint="Precio por hora de Asesoria Grupal"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-alert v-if="curso.id == -1"
        dense
        type="error"
      >
        Error debe <strong>SELECCIONAR UN CURSO</strong> para crear una asesoria
      </v-alert>

      <v-toolbar class="my-4">
        <v-toolbar-title>
          Agendar nueva asesoría
          <v-icon class="ml-1">{{ icons.mdiArrowDown }}</v-icon>
        </v-toolbar-title>
      </v-toolbar>

      <!--Calendario-->
      <template>
        <v-row class="fill-height">
          <v-col>
            <v-sheet height="64">

              <v-toolbar flat color="white">
                <v-btn outlined class="mr-4" @click="setToday">
                  Hoy
                </v-btn>
                <v-btn fab text small @click="prev">
                  <v-icon small>keyboard_arrow_left</v-icon>
                </v-btn>
                <v-btn fab text small @click="next">
                  <v-icon small>keyboard_arrow_right</v-icon>
                </v-btn>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <template v-if="seleccionando">
                  <v-btn color="success" class="mr-4" @click="validate">Crear Asesoria</v-btn>
                  <v-btn color="error" class="mr-4" @click="cancelar">Cancelar</v-btn>
                </template>
                <template v-else>
                  <v-btn color="success" class="mr-4" @click="seleccionar">Seleccionar</v-btn>
                </template>
              </v-toolbar>

            </v-sheet>

            <v-alert
              class="mt-2"
              :value="alertSelectDisabled"
              color="error"
              dark
              border="top"
              icon="mdi-home"
              transition="scale-transition"
            >
            Para seleccionar un bloque de horas, dale click al botón “Seleccionar”
            </v-alert>

            <v-sheet height="760">
              <v-responsive :aspect-ratio="16/9">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:event="seleccionar_evento"
                @change="updateRange"
                :weekdays="formato_semana"
                locale="es"
                :short-weekdays="true"
                first-interval="6"
                interval-count="17"
              ></v-calendar>
              </v-responsive>
            </v-sheet>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-form>
</template>

<style>
  .v-data-table tbody tr {
  margin-bottom: 50px; 
  }
  .w-100 {
    width: 100%;
  }
  .Sobre_tutor{
    width: 650px;
    height: 315px;
  }
  @media (min-width: 700px) and (max-width: 1280px){
    .Sobre_tutor{
      display: block;
      margin: 0 auto;
      width: 650px;
      height: 315px;
  }
  }
  @media (max-width: 700px){
    .Sobre_tutor{
      display: block;
      margin: 0 auto;
      width: 100%;
      height: 250px;
  }
  }
  .el_tutor{
    margin: -5px !important;
  }
</style>

<script>
import { axiosInstance } from "../../service/index";
import {mapState, mapMutations} from 'vuex';
import { mdiArrowDown } from '@mdi/js';

export default {
  data: () => ({
    icons: {
      mdiArrowDown
    },
    expand: false,
    seleccionando: false,
    deshabilitado: true,
    dialog_datos_profesor: false,
    rating: 0,
    comentarios: '',
    data_comentarios: [],
    alertRating: false,
    alertComentarios: false,
    alertSelectDisabled: false,
    profesorData:{
      nombre: "",
      apellido: "",
      nacimiento: "",
      universidad: "",
      carrera: "",
      descripcion: "",
      ciclo_actual: 0,
      foto_url: '',
      video_url: ''
    },
    starts1: 0,
    starts2: 0,
    starts3: 0,
    starts4: 0,
    starts5: 0,
    //data escoger curso
    cursodialog: false,
    dialog_comentarios: false,
    cursos_headers: [
      { text: 'Institucion', align: 'left', value: 'nombre_insitucion' },
      { text: 'Nombre del Curso', align: 'left', value: 'nombre' },
      { text: 'Precio Individual', align: 'left', value: 'precio_individual' },
      { text: 'Precio Grupal', align: 'left', value: 'precio_grupal' },
      { text: 'Acciones', align: 'center', value: 'action', sortable: false }
    ],
    cursos_profesor: [],
    curso_search: '',
    curso: {
      id: -1, 
      nombre: null, 
      precio_individual: null, 
      precio_grupal: null
    },

    dialog_asesorias: {
      show: false,
      asesorias: [],
      search: '',
      headers: [
        { text: 'Curso', value: 'curso' },
        { text: 'Tipo', value: 'tipo', align: 'center' },
        { text: 'Fecha', value: 'fecha', align: 'center' },
        { text: 'Hora inicio', value: 'hora', align: 'center' },
        { text: 'Hora fin', value: 'hora_fin', align: 'center' },
        { text: 'Precio', value: 'precioFinal', align: 'center' },
        { text: 'Acciones', value: 'action', align: 'center', sortable: false },
      ],
    },

    //data calendario
    formato_semana: [1,2,3,4,5,6,0],
    today: '',
    focus: '',
    type: 'week',
    start: null,
    end: null,
    selectedEvents: [],
    events: [],

    //data mensaje de error
    msgbox: false,
    msgtitulo: '',
    msgtexto: '',
}),
  created(){
    this.obtenerCursosProfesor()
    this.obtenerRating()
    this.obtenerProfesor()
    this.inicializar_eventos()
    this.obtenerAsesorias()
    console.log(this.$hostname);
  },
  computed: {
    ...mapState([
      'newAsesoria',
      'horas_dictadas_x_curso',
      'userData',
      'userAlumno',
      'currentJWT',
      'cursosBusquedaList'
    ]),
    id() {
      return this.$route.params.id
    },
    title () {
      const { start, end } = this
      if (!start || !end) {
        return ''
      }
      const startMonth = this.monthFormatter(start)
      const endMonth = this.monthFormatter(end)
      const suffixMonth = startMonth === endMonth ? '' : endMonth
      const startYear = start.year
      const endYear = end.year
      const suffixYear = startYear === endYear ? '' : endYear
      const startDay = start.day
      const endDay = end.day
      
      if(startYear != endYear){
        return `${startDay} ${startMonth} ${startYear} - ${endDay} ${suffixMonth} ${endYear}`
      } else if(suffixMonth != '') {
        return `${startDay} ${startMonth} al ${endDay} ${suffixMonth} del ${startYear}`
      } else {
        return `${startDay} al ${endDay} de ${startMonth} del ${startYear}`
      }
      
    },
    monthFormatter () {
      return this.$refs.calendar.getFormatter({
        timeZone: 'UTC', month: 'long',
      })
    },
  },
  mounted () {
    this.$refs.calendar.checkChange()
  },
  methods:{
    ...mapMutations([
      'saveStateToLocalStorage'
    ]),
    mostrar_comentarios(){
      let auxURL = '/api/negocio/getComentariosByProfesorAndCurso?idProfesor='+this.id+'&idCurso='+this.curso.id
      axiosInstance.get(auxURL)
      .then(res => {
        if (res.data != false) {
          this.data_comentarios = res.data
        }
      }).catch(err =>{
        console.log('Error:')
        console.log(err)
      })
    },
    obtenerProfesor () {
      let me = this
      let auxURL = '/api/profesor/getInfoProfe?idProfesor=' + me.id
      axiosInstance.get(auxURL)
      .then(res => {
        me.profesorData = res.data;
        if (me.profesorData.ciclo_actual == 69) {
          me.profesorData.ciclo_actual = 'Egresado'
        }
      }).catch(err =>{
        console.log('Error:')
        console.log(err)
      })
    },
    obtenerRating(){
      
      let auxURL = '/api/negocio/getRatingByIdProfesor?idProfesor='+this.id
      axiosInstance.get(auxURL)
      .then(res => {
        if(res.data != false){
          var rat = 0;
          var cant = 0;
          
            this.starts1 = 0;
            this.starts2 = 0;
            this.starts3 = 0;
            this.starts4 = 0;
            this.starts5 = 0;
          for (var i = 0; i<res.data.length; i++){
            rat += (res.data[i].rating*res.data[i].cant)
            cant +=res.data[i].cant;

            if(res.data[i].rating == 1){
              this.starts1 = res.data[i].cant;
            }else if(res.data[i].rating == 2){
              this.starts2 = res.data[i].cant;
            }else if(res.data[i].rating == 3){
              this.starts3 = res.data[i].cant;
            }else if(res.data[i].rating == 4){
              this.starts4 = res.data[i].cant;
            }else if(res.data[i].rating == 5){
              this.starts5 = res.data[i].cant;
            }
          }
          this.rating = rat/cant;
        }
      }).catch(err =>{
        console.log('Error:')
        console.log(err)
      })
    },
    changeRating(){
      if(this.validarLogin()){
        //console.log('usuario logeado como alumno')
        this.alertRating = true
        let auxURL = '/api/negocio/putRatingByIdProfesor?idProfesor='+this.id+'&rating='+this.rating+'&idAlumno='+
        this.currentJWT.id;
        axiosInstance.put(auxURL)
        .then(res => {
          this.obtenerRating()
        }).catch(err =>{
          console.log('Error:')
          console.log(err)
        })

        setTimeout(()=>{
          this.alertRating=false
        },3000)
      } else {
        this.$router.push({name:'alumnologin'})
      }
    },
    guardarComentario(){

      if(this.validarLogin()){        
        this.alertComentarios = true
        let auxURL = '/api/negocio/putComentaryByCursoAndProfesorAndAlumno?idProfesor='+this.id+'&idAlumno='+
        this.currentJWT.id+'&idCurso='+this.curso.id+'&comentarios='+this.comentarios
        axiosInstance.put(auxURL)
        .then(res => {
          this.obtenerRating()
          this.comentarios = ''
          this.mostrar_comentarios()
        }).catch(err =>{
          console.log('Error:')
          console.log(err)
        })

        setTimeout(()=>{
          this.alertComentarios=false
        },3000)
      } else {
        this.$router.push({name:'alumnologin'})
      }
    },
    obtenerCursosProfesor () {
      let me = this
      let auxURL = '/api/profesor/getCursosByProfesor?idProfesor=' + me.id
      axiosInstance.get(auxURL)
      .then(res => {
        me.cursos_profesor = res.data;
        if(me.newAsesoria.idCurso != -1){
          me.methodagregarcurso(me.cursos_profesor.find(x => x.id == me.newAsesoria.idCurso))
        }
      }).catch(err =>{
        console.log('Error:')
        console.log(err)
      })
    },
    obtenerAsesorias () {
      this.dialog_asesorias.asesorias = [];
      axiosInstance.get(`/api/asesoria/getActivasByProfesor/${this.id}`)
        .then(response => {
          if (response.data) {
            this.dialog_asesorias.asesorias = response.data;
          }
        });
    },
    inicializar_eventos () {
      let fecha_ini = new Date();
      let fecha_fin = new Date();
      fecha_ini = this.addDays(fecha_ini, 1)
      fecha_fin = this.addDays(fecha_fin, 8)

      let me = this
      let auxURL = '/api/profesor/getDisponibilidadProfesor?idProfesor=' + me.id
      axiosInstance.get(auxURL)
      .then(res => {
        me.methodprocesarhorario(res.data)
      }).catch(err =>{
        console.log('Error:')
        console.log(err)
      })
    },
    methodprocesarhorario (data) {
      let me = this;
      data.forEach(function (element) {
        element.name = "Disponible";
        element.color = 'green';
      });
      me.events = data;
    },
    methodagregarcurso(item){
      this.curso = Object.assign({}, item);
      this.$store.state.horas_dictadas_x_curso = item.horas_dictadas
      this.newAsesoria.idCurso = this.curso.id;
      this.newAsesoria.nombre_curso = this.curso.nombre;
      this.mostrar_comentarios();
      this.cursodialog = false;
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = this.today
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    seleccionar_evento ({ nativeEvent, event }) {
      if (this.deshabilitado != true) {
        if (event.name == 'Disponible'){
          this.selectedEvents.push(event)
        } else {
          this.selectedEvents.splice(this.selectedEvents.indexOf(event), 1)
        }

        event.name = (event.name == 'Disponible' ? 'Seleccionado' : 'Disponible')
        event.color = (event.color == 'green' ? 'blue darken-1' : 'green')
        nativeEvent.stopPropagation()
      } else {
        this.alertSelectDisabled = true
      }
    },
    updateRange ({ start, end }) {
      this.start = start
      this.end = end
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    formatDate (a, withTime) {
      return withTime
        ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
        : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
    },
    addDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    seleccionar () {
      this.seleccionando=true;
      this.deshabilitado=false;
    },
    cancelar () {
      this.selectedEvents = [];
      for(let i = 0; i < this.events.length; i++){
        this.events[i].name = 'Disponible'
        this.events[i].color = 'green'
      }
      
      this.seleccionando = false;
      this.deshabilitado=true; 
    },
    validarLogin() {
      let me = this;
      if(me.userProfesor == true){
        console.log("Error: estas logeado como profesor, no puedes unirte a una asesoria")
      }else if(me.userAlumno == false){
        return false;
      }else if(me.userAlumno == true){
        return true;
      }
    },
    validate () {
      let me = this;
      if(this.selectedEvents.length >= 2){
        let fecha1 = this.selectedEvents[0].start.split(' ')[0]
        let error1 = false;
        for(let i = 0; i < this.selectedEvents.length; i++){
          if(this.selectedEvents[i].start.split(' ')[0] != fecha1){
            error1 = true;
          }
        }
        if(error1 == true){
          me.msgbox = true;
          me.msgtitulo = 'ERROR: Días Distintos'
          me.msgtexto = 'Las horas seleccionadas no pueden estar en días distintos'
        }else{
          let horas = [];
          for(let j = 0; j < this.selectedEvents.length; j++){
            horas.push(parseInt(this.selectedEvents[j].start.split(' ')[1].split(':')[0]))
          }
          horas.sort(function(a, b){return a-b});
          let error2 = false;
          for(let k = 0; k < horas.length - 1; k++){
            if(horas[k+1] - horas[k] > 1){
              error2 = true;
            }
          }
          if(error2 == true){
            me.msgbox = true;
            me.msgtitulo = 'ERROR: Horas separadas'
            me.msgtexto = 'Las horas seleccionadas deben ser contiguas'
          } else {
            //----------------------
            this.selectedEvents.sort((a,b) => (a.start > b.start) ? 1 : ((b.start > a.start) ? -1 : 0))
            this.crear()
          }
        }
      } else if (this.selectedEvents.length == 1){
        this.crear()
      } else {
        me.msgbox = true;
        me.msgtitulo = 'ERROR: Sin Horas'
        me.msgtexto = 'Debe escoger al menos una hora'
      }
    },
    crear () {
      if(this.curso.id == -1){
        this.msgtitulo = 'ERROR: No hay Curso'
        this.msgtexto = 'Debe agregar un curso para crear la asesoria'
        this.msgbox = true;
      } else {
        this.newAsesoria.idProfesor = this.$route.params.id
        this.newAsesoria.nombre_profe = this.profesorData.nombre
        this.newAsesoria.fecha = this.selectedEvents[0].start.split(' ')[0]
        this.newAsesoria.hora_ini = this.selectedEvents[0].start.split(' ')[1]
        this.newAsesoria.hora_fin = this.selectedEvents.slice(-1)[0].end.split(' ')[1]
        //console.log(this.newAsesoria)
        this.saveStateToLocalStorage()
        this.$router.push({name:'crear_asesoria'})
      }
    },
    cerrar_mensaje(){
      let me = this;
      me.msgbox = false;
      me.msgtitulo = '';
      me.msgtexto = '';
    },
    formatDate(date = new Date()) {
      const year = date.toLocaleString('default', { year: 'numeric' });
      const month = date.toLocaleString('default', { month: '2-digit' });
      const day = date.toLocaleString('default', { day: '2-digit' });
      return [day, month, year].join('/');
    }
  },
}
</script>