<template>
  <v-container>

    <v-dialog v-model="msgbox" width="600px">
      <v-card>
        <v-card-title class="headline" v-text="msgtitulo"></v-card-title>
        <v-card-text class="text--primary" color="black" v-text="msgtexto"></v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="success" text @click="msgbox = false">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout text-xs-center wrap>
      <v-flex xs12>
        <v-form ref="form" v-model="valid">

            <v-toolbar>
                <v-toolbar-title>
                    Inicio
                </v-toolbar-title>
            </v-toolbar>

          <v-row>

            <v-col cols="12" sm="6" md="6">
              <template>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                      <v-text-field
                        :rules="fechaRules"
                        v-model="fechainicio"
                        label="Fecha de Inicio"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="fechainicio"
                    :min="today"
                    @change="save1"
                  ></v-date-picker>
                </v-menu>
              </template>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="horainicio"
                :items="horas"
                item-value="value"
                label="Selecciona una hora de inicio"
                :rules="horaRules"
              ></v-select>
            </v-col>

            

          </v-row>

          <v-toolbar>
                <v-toolbar-title>
                    Fin
                </v-toolbar-title>
            </v-toolbar>

          <v-row>

            <v-col cols="12" sm="6" md="6">
              <template>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                      <v-text-field
                        :rules="fechaRules"
                        v-model="fechafin"
                        label="Fecha de Fin"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="fechafin"
                    :min="(fechainicio == null ? today : fechainicio)"
                    @change="save2"
                  ></v-date-picker>
                </v-menu>
              </template>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                v-model="horafin"
                :items="horas"
                item-value="value"
                label="Selecciona una hora de fin"
                :rules="horaRules"
              ></v-select>
            </v-col>


          </v-row>

          <v-row justify="center">
            <v-btn :disabled="!valid" color="success" class="mr-4 mb-4" @click="validate">Guardar</v-btn>
            <v-btn color="warning" class="mr-4 mb-4" :to="{name:'profesorinicio'}">Regresar</v-btn>
          </v-row>

        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
import { axiosInstance } from "../../service/index";
import {mapState, mapMutations} from 'vuex'
export default {
  data () {
    return {
      msgbox: false,
      msgtitulo: '',
      msgtexto: '',

      menu1: false,
      fechainicio: null,
      horainicio: null,

      menu2: false,
      fechafin: null,
      horafin: null,


      today: new Date().toISOString().substr(0, 10),


      valid: false,
      horas:[
        {text:'7 am',value:7},
        {text:'8 am',value:8},
        {text:'9 am',value:9},
        {text:'10 am',value:10},
        {text:'11 am',value:11},
        {text:'12 pm',value:12},
        {text:'1 pm',value:13},
        {text:'2 pm',value:14},
        {text:'3 pm',value:15},
        {text:'4 pm',value:16},
        {text:'5 pm',value:17},
        {text:'6 pm',value:18},
        {text:'7 pm',value:19},
        {text:'8 pm',value:20},
        {text:'9 pm',value:21},
      ],
      horaRules:[
        v => !!v || 'Se necesita de la hora'
      ],
      fechaRules:[
        v => !!v || 'Se necesita de la fecha'
      ]
    }
  },
  computed:{
    ...mapState([
      'currentJWT'
      ])
  },
  created(){ 
  },
  methods:{
    save1 (date) {
      this.$refs.menu1.save(date)
    },
    save2 (date) {
      this.$refs.menu2.save(date)
    },
    validate () {
      var me = this
      if (me.$refs.form.validate()) {
        me.snackbar = true
        me.validar()
      }
    },
    validar(){
      let me = this;
      if(me.fechainicio == me.fechafin){
        if(me.horainicio < me.horafin){
          me.enviar()
        }else{
          me.msgbox= true
          me.msgtitulo= 'Error'
          me.msgtexto= 'La hora inicio no puede ser mayor a la hora fin'
        }
      }else if(me.fechainicio < me.fechafin){
        me.msgbox= true
        me.msgtitulo= 'Error'
        me.msgtexto= 'El registro debe ser del mismo dia'
      }else if(me.fechainicio > me.fechafin){
        me.msgbox= true
        me.msgtitulo= 'Error'
        me.msgtexto= 'La fecha  inicio no puede ser mayor a la fecha fin'
      }
    },

    enviar(){
      let me = this;
      //FORMATO A LA hora
      if(me.horainicio<10){
        me.horainicio = '0'+me.horainicio;
      }
      if(me.horafin<10){
        me.horafin = '0'+me.horafin;
      }
      //VALIDAR fechaa
      if(me.fechainicio != me.fechafin){
        me.msgbox= true
        me.msgtitulo= 'Error'
        me.msgtexto= 'No puede seleccionar dias diferentes'
      }
      let auxURL = '/api/profesor/setDiaNoDisponible?fechaInicio=' + me.fechainicio + '&fechaFin=' + me.fechafin + '&horaInicio=' + me.horainicio + ':00:00&horaFin=' + me.horafin + ':00:00';
      axiosInstance.post(auxURL)
        .then(res => {
          me.msgbox= true
          me.msgtitulo= 'Mensaje'
          me.msgtexto= res.data
        }).catch(err =>{
          console.log(err)
          me.msgtitulo = 'Error:';
          me.msgtexto = err;
          me.msgbox = true;
        });
      me.clear()
    },
    clear(){
      this.$refs.form.reset();
    }
  }
}
</script>
