<template>
    <button type="button" class="fb-login-button mr-4 v-btn v-btn--is-elevated v-btn--has-bg v-size--default" @click="logInWithFacebook">
        CONTINUAR CON FACEBOOK
    </button>
</template>
<script>
import { loadFacebookSDK, initFacebook } from './facebookService';
export default {
    name: "facebookLogin",
    mounted() {
        loadFacebookSDK(document, "facebook-jssdk");
        initFacebook();
    },
    methods: {
        logInWithFacebook() {
            let me = this;
            window.FB.login(function(response) {
                if (response.authResponse) {
                    window.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
                        function(response) {
                            // invocar al método login que se envía por props desde el componente padre (Login.vue) y pasarle los datos del usuario de Facebook
                            me.$emit('login', {
                                id: response.id,
                                name: response.name,
                                email: response.email || null,
                                picture: response.picture.data.url
                            })
                        }
                    );
                }
            });
        },
    },
};
</script>
<style>
.fb-login-button {
    background-color: #3b5998;
    color: #fff;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;
    padding: 6px 20px;
    max-height: 36px;
    height: 36px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>