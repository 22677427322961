<template>
  <v-container>
    <v-layout column allign-center>
        <v-col align="center" justify="center">

            <v-row align="center" justify="center">
                <v-img
                max-height="250px"
                max-width="250px"
                src="../assets/email.png"
                ></v-img>
            </v-row>

            <v-divider class="mx-4" inset vertical ></v-divider>

            <v-row align="center" justify="center">
                <h1>Te hemos enviado un correo electrónico de activación</h1>
            </v-row>

            <v-divider class="mx-4" inset vertical ></v-divider>
            
            <v-row align="center" justify="center">
                <!-- v-toolbar-title class="text-wrap">Hemos enviado un correo electrónico a tu correo con un enlace para activar tu cuenta. Sigue las instrucciones para completar el registro de la cuenta.</v-toolbar-title -->
                <v-toolbar-title class="text-wrap">Ya puedes iniciar sesión con tu usuario y contraseña.</v-toolbar-title>
            </v-row>

        </v-col>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  data () {

    return {
      
    }
  },


}
</script>
