<template>
  <v-container>
    <v-data-table :headers="headers" :items="alumnos" :search="search" class="elevation-1">
      <template v-slot:top>
        <div class="d-flex flex-column pa-2">
          <v-toolbar flat>
            <v-toolbar-title>Alumnos</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="info" class="mr-2" dark link :to="{ name: 'profesoretiquetas' }">
              <v-icon class="mr-2">{{ icons.mdiTag }}</v-icon>
              Etiquetas
            </v-btn>
            <v-btn color="secondary" dark link :to="{ name: 'profesordifusion' }">
              <v-icon class="mr-2">{{ icons.mdiEmailArrowRight }}</v-icon>
              Mensaje
            </v-btn>
          </v-toolbar>
          <div class="px-4 mb-4">
            <v-text-field v-model="search" label="Buscar" single-line hide-details></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn color="info" dark small @click="openDialog(item)">
          <v-icon class="mr-2">{{ icons.mdiTagTextOutline }}</v-icon>
          Vincular etiquetas
        </v-btn>
      </template>
      <template v-slot:no-data>
        <div class="my-2">
          <p class="mb-1">No se encontraron alumnos</p>
          <v-btn color="primary" @click="initialize">
            Reset
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <v-dialog persistent max-width="600" v-model="dialog.show">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">Vincular etiquetas</v-card-title>
        <v-form ref="form">
          <v-card-text>
            <v-container>
              <v-select v-model="form.etiquetas" :items="etiquetas" multiple chips label="Etiquetas"></v-select>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn color="success" :loading="dialog.loading" :disabled="dialog.loading" text @click="onSave()">
              Guardar
            </v-btn>
            <v-btn text @click="closeDialog()">Cerrar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>
@media (max-width: 600px){
  .v-select__selection--comma{
  width: auto !important;
}
}

@media (min-width: 600px) and (max-width: 960px){
  .v-select__selection--comma{
  width: auto !important;
}
}
</style>
  
<script>
import { axiosInstance } from "../../service/index";
import { getByProfesor } from "../../service/EtiquetaService";
import { linkAlumnoEtiquetas } from "../../service/profesorService";
import { mdiTag, mdiEmailArrowRight, mdiTagTextOutline } from '@mdi/js';

export default {
  data: () => ({
    icons: {
      mdiTag,
      mdiEmailArrowRight,
      mdiTagTextOutline
    },
    dialog: false,
    search: '',
    headers: [
      { text: 'Nombre', value: 'nombre' },
      { text: 'Correo', value: 'correo' },
      // { text: 'Celular', value: 'celular', align: 'center' },
      { text: 'Universidad', value: 'universidad', align: 'center' },
      { text: 'Opciones', value: 'actions', align: 'center' },
    ],
    alumnos: [],
    etiquetas: [],
    form: {
      etiquetas: []
    },
    dialog: {
      id: 0,
      show: false,
      loading: false,
    },
  }),
  created() {
    this.getAlumnos();
    this.getTags();
  },
  methods: {
    getAlumnos() {
      this.alumnos = [];
      axiosInstance.get('/api/profesor/getAlumnosAsesoriaByProfesorAuth')
        .then(response => {
          if (response.data) {
            this.alumnos = response.data;
          }
        });
    },
    async getTags() {
      const etiquetas = await getByProfesor();
      this.etiquetas = etiquetas.map(item => {
        return { text: item.nombre, value: item.id };
      })
    },
    openDialog(item) {
      this.form.etiquetas = item.etiquetas.map(item => item.id);
      this.dialog.show = true;
      this.dialog.id = item.id;
    },
    closeDialog() {
      this.dialog.show = false;
      this.dialog.loading = false;
      this.dialog.id = 0;
      this.$refs.form.reset();
    },
    async onSave() {
      this.dialog.loading = true;
      if (this.dialog.id) {
        const response = await linkAlumnoEtiquetas(this.dialog.id, this.form.etiquetas);
        if (!response.success) {
          this.dialog.loading = false;
          swal("Error", response.data.message, "error");
          return;
        }

        this.closeDialog();
        this.getAlumnos();
        this.getTags();
        swal("Éxito", response.data.message, "success");
      }
    },
  },
}
</script>