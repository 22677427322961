<template>
<v-form >
  <v-container class="cont1">
    <v-layout text-xs-center wrap>
      <v-row justify="center">
        <v-col class="layoutBuscar" cols="12" sm="8" md="6" lg="4">
          <v-autocomplete
            class="buscandor"
            outlined
            color="#000000"
            background-color="yellow"
            v-model="busqueda_institucion"
            :items="institucionesBusquedaList"
            item-value="id"
            item-text="nombre"
            label="Buscar Institucion"
            hide-no-data
            solo
          ></v-autocomplete>
          <v-autocomplete
            class="buscandor"
            outlined
            color="#000000"
            background-color="yellow"
            v-model="busqueda_curso"
            :items="cursosByInstitucion"
            item-value="id"
            item-text="nombre"
            label="Buscar Curso"
            solo
            no-data-text="Buscar Institucion primero"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>

  <v-container justify="center" class="cont2">
    </br>
    <h2 style="color:#ec6910" class="textcentrado">ACERCA DE NOSOTROS </h2>
    <br>
    <h4 class="Parrafo">Learn It es una start up educativa, que facilita a alumnos como tu 
    la busqueda y reserva de una asesoria con un tutor academico
    que se ajuste a tus necesidades del usuario, ofreciendo una
    interfaz que elimine los riesgos y vuelva más eficientes los
    procesos al comprar y recibir el servicio de la asesoria</h4>

    </br>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="10" lg="12" >
        <v-img
        class="Parrafo"
        height="400px"
        width="400px"
        src="../assets/como-funciona.png"
        ></v-img>
      </v-col>
    </v-row>

    <v-row class="group-op" justify="center">

      <v-col class="group-op" cols="12" sm="4" md="2" lg="2" xl="2">
        <v-img
        class="Opciones"
          src="../assets/1.png"
        ></v-img>
        
      </v-col>
      <v-col class="group-op" cols="12" sm="4" md="2" lg="2" xl="2">
        <v-img
        class="Opciones"
          src="../assets/2.png"
        ></v-img>
      </v-col>
    
      <v-col class="group-op" cols="12" sm="4" md="2" lg="2" xl="2">
        <v-img
        class="Opciones"
          src="../assets/3.png"
        ></v-img>
      </v-col>
      <v-col class="group-op" cols="12" sm="4" md="2" lg="2" xl="2">
        <v-img
        class="Opciones"
          src="../assets/4.png"
        ></v-img>
      </v-col>

      <v-col class="group-op" cols="12" sm="4" md="2" lg="2" xl="2">
        <v-img
        class="Opciones"
          src="../assets/5.png"
        ></v-img>
      </v-col>
    </v-row>
    </br>

    <h1 style="color: #ec6910" class ="textcentrado"><b>Con Learn It tienes acceso a ...</b></h1>
    <br>
    <br>
    <v-row justify="center">
        <v-col cols="12" md="8" lg="8" xl="8" xxl="8">
          <v-carousel class="carrucel" :cycle=true interval="5000">
            <v-carousel-item
              v-for="(item,i) in carrouselimages"
              :key="i">
              <v-img :src="item.src" style="max-width: 100%; height: auto; object-fit: cover;"></v-img>
          </v-carousel-item>
          </v-carousel>
        </v-col>
      <br>
        <v-col cols="8" md="4" lg="4" xl="4" xxl="4">
          <v-img
            src="../assets/parte-equipo.png"
          ></v-img>
        </v-col>
      
    </v-row>
    <!-- <v-row justify="center">
      <v-col cols="12" md="10" lg="10">
        <v-img
          max-height="1100"
          max-width="100%"
          src="../assets/soporte.png"
        ></v-img>
      </v-col>
    </v-row> -->

    

    <v-row justify="center">
      <v-col  id="postularlayout" xs="8" sm="10" md="12">
        <v-btn class="btnPostular" target="_blank" :to="{name:'profesorcrearcuenta'}"><b>Postular</b></v-btn>
      </v-col>
    </v-row>

  </v-container>
  </br>
  <v-container id="footer" justify="center" fluid>
    <v-row justify="center">
      <v-col  cols="12">
      <h2 class="textcentrado" style="color: #ec6910 ">CONTÁCTANOS</h2>
      </v-col>
      <v-col  cols="4" class="text-right">
        <a class="terminos" href="https://learnit-asesorias.com/politicasdeprivacidad.pdf" target="_blank">Términos y condiciones</a>
      </v-col>
      <v-col  cols="4" class="text-left">
        <a class="terminos" href="https://learnit-asesorias.com/politicasdeprivacidad.pdf" target="_blank">Políticas de privacidad</a>
      </v-col>
    </v-row>

    <v-row cols="2">
      <v-col justify="center" align="center"  sm="3">
      <a  style="margin:20px" target="_blank" href="https://www.facebook.com/learnit.com.pe/">
        <v-img class="red-social"
            
            src="../assets/facebook512.png"
        ></v-img>
      </a>
    </v-col>
    <v-col  justify="center" align="center"  sm="3">
      <a  style="margin:20px" target="_blank" href="https://m.me/learnit.com.pe">
        <v-img class="red-social"
            
            src="../assets/messenger512.png"
        ></v-img>
      </a>
    </v-col>
    <v-col justify="center" align="center"   sm="3">
      <a style="margin:20px" target="_blank" href="https://wa.me/message/SJWUT4QGB6WSI1">
        <v-img class="red-social"
            
            src="../assets/whatsapp512.png"
        ></v-img>
      </a>
    </v-col>
    <v-col justify="center" align="center"  sm="3">
      <a style="margin:20px" target="_blank" href="mailto:learnit.latinoamerica@gmail.com">
        <v-img class="red-social"
        
            src="../assets/gmail512.png"
        ></v-img>
      </a>
    </v-col>
    </v-row>
  </v-container>
</v-form>
</template>


<style>
  .terminos{
    color: white !important;
  }
  .carrucel{
    width: 700px !important;
    height: 300px !important;
    /* background-color: blue; */
  }
  .v-carousel__controls{
      display: none;
    }
    @media (min-width: 1920px) and (max-width: 2560px){
      .carrucel{
    width: 1000px !important;
    height: 500px !important;
    margin: 0 auto;
    }
    .v-carousel__controls{
      display: none;
    }
  }
  @media (min-width: 960px) and (max-width: 1263px){
      .carrucel{
    max-width: 100% !important;
    height: 300px !important;
    margin: 0 auto;
    }
    .v-carousel__controls{
      display: none;
    }
  }
  @media (min-width: 752px) and (max-width: 960px){
      .carrucel{
    max-width: 100% !important;
    height: 380px !important;
    margin: 0 auto;
    }
    .v-carousel__controls{
      display: none;
    }
  }
  @media (min-width: 640px) and (max-width: 752px){
      .carrucel{
    max-width: 100% !important;
    height: 330px !important;
    margin: 0 auto;
    }
    .v-carousel__controls{
    display: none;
    }
  }
  @media (min-width: 502px) and (max-width: 640px){
      .carrucel{
    max-width: 100% !important;
    height: 270px !important;
    margin: 0 auto;
    }
    .v-carousel__controls{
    display: none;
    }
  }
  @media (min-width: 382px) and (max-width: 502px){
      .carrucel{
    max-width: 100% !important;
    height: 210px !important;
    margin: 0 auto;
    }
    .v-carousel__controls{
    display: none;
    }
  }
  @media (max-width: 382px){
      .carrucel{
    max-width: 100% !important;
    height: 160px !important;
    margin: 0 auto;
    }
    .v-carousel__controls{
    display: none;
    }
  }
  /* .v-window .v-window-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 10% !important;
    } */
    
  .Parrafo{
    text-align: center;
    margin: 0 auto;
  }
  .textcentrado {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
  .red-social{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0%;
  }
  @media (max-width: 600px) {
    .red-social{
    max-height: 40px;
    max-width: 40px;
  }
  }
  @media (min-width: 600px) and (max-width: 960px) {
    .red-social{
    max-height: 55px;
    max-width: 55px;
  }
  }
  @media (min-width: 960px) and (max-width: 1280px) {
    .red-social{
    max-height: 55px;
    max-width: 55px;
  }
  }
  @media (min-width: 1280px) and (max-width: 1920px) {
    .red-social{
    max-height: 60px;
    max-width: 60px;
  }
  }
  @media (min-width: 1920px) and (max-width: 2560px) {
    .red-social{
    max-height: 50px;
    max-width: 50px;
  }
  }
  .group-op{
    margin-top: 50px;
    
  }
  @media (max-width: 600px){
    .group-op{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  }
  .Opciones{
    display: flex;
    justify-content: center;
    max-height: 550px;
    max-width: 250px;
  }
  @media (max-width: 600px){
    .Opciones{
    width: 600px;
    height: 250px;
  }
  }
  #footer{
    background-color: #1c2344;
  }
  .btnPostular{
    position: relative;
    border-radius: 90px;
    font-size: 30px !important;
    padding: 40px;
    height: 70px !important;
    width: 320px;

  }
  @media (max-width: 600px){
    .btnPostular {
    height: 50px !important;
    width: 200px;
    padding: 5px;
    font-size: 20px !important;
  }
  }
  @media (min-width: 601px) and (max-width: 960px){
    .btnPostular {
    top: -250% !important;
    height: 50px !important;
    width: 300px;
    padding: 5px;
    font-size: 30px !important;
  }
  }
  
  #postularlayout{
    display: flex;
    justify-content: center;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
    
  }
  .cont1{  
    background-image: url("../assets/inicio.png");
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat;
    max-width: 100% !important;
    height: 900px;
  }
  @media (max-width: 600px) {
    .cont1{  
    width: 100% !important;
    background-position: center; 
    background-repeat: no-repeat;
    height: 400px;
  }
  }
  @media (min-width:850px) and (max-width: 960px){
    .cont1{  
    width: 100% !important;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: contain;
    height: 650px;
  }
  }
  @media (min-width:601px) and (max-width: 850px){
    .cont1{  
    width: 100% !important;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: contain;
    height: 550px;
  }
  }
  .v-label.theme--light {
    color: rgba(0, 0, 0) !important;
    font-weight: bolder;
  }
  .layoutBuscar{
    margin-top: 15%;
    margin-left: 50%;
    width: 100px;
    height: 20px;
  }
  @media (max-width: 450px){
    .layoutBuscar{
    margin-top: 30%;
    margin-left: auto;
  }
  }
  @media (min-width: 451px) and (max-width: 600px){
    .layoutBuscar{
    margin-top: 20%;
    margin-left: auto;
  }
  }
  @media (min-width: 601px) and (max-width: 800px){
    .layoutBuscar{
    margin-top: 20%;
    margin-left: auto;
  }
  }
  @media (min-width: 801px) and (max-width: 960px){
    .layoutBuscar{
    margin-top: 15%;
    margin-left: auto;
    }
  }
  .v-select__selection,
  .v-select__selection--comma,
  .layoutBuscar > div > div > div.v-input__slot{
    margin-left: auto;
    width: 380px;
    height: 20px !important;
    border-radius: 50px;
  }
  @media (min-width: 601px) and (max-width: 801px){
    .layoutBuscar > div > div > div.v-input__slot{
    width: 300px;
    height: 30px;
  }
  }
  @media (max-width: 600px){
    .layoutBuscar > div > div > div.v-input__slot{
    width: 250px;
  }
  }
</style>


<script>
import {mapState, mapMutations} from 'vuex'
export default {
  data () {
    return {
      busqueda_institucion: null,
      busqueda_curso: null,
      cursosByInstitucion: [],
      carrouselimages: [
          {
            src:  require('../assets/carrousel1.png'),
          },
          {
            src: require('../assets/carrousel2.png'),
          },
          {
            src:  require('../assets/carrousel3.png'),
          },
          {
            src:  require('../assets/carrousel4.png'),
          },
        ],
    }
  },
  computed:{
    ...mapState([
      'institucionesBusquedaList',
      'cursosBusquedaList',
    ]),
  },
  watch: {
    busqueda_institucion () {
      if(this.busqueda_institucion != null){
        this.filtrarCursosByInstitucion();
      }
    },
    busqueda_curso () {
      if(this.busqueda_curso != null){
        this.setCursoBusqueda(this.busqueda_curso);
        this.$router.push({name:'buscar_curso'});
      }
    }
  },
   created() {
    let me = this
    me.getCursosyInstitucionesBusquedaList()
  },
  methods: {
    ...mapMutations([
      'getCursosyInstitucionesBusquedaList',
      'setCursoBusqueda'
    ]),

    filtrarCursosByInstitucion(){
      this.cursosByInstitucion = []
      this.cursosBusquedaList.forEach(element => {
        if (element.id_institucion == this.busqueda_institucion) {
          this.cursosByInstitucion.push(element)
        }
      })
    }
  }
}
</script>

