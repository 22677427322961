<template>
  <v-container>

    <v-dialog v-model="msgbox" width="600px">
      <v-card>
        <v-card-title class="headline" v-text="msgtitulo"></v-card-title>
        <v-card-text class="text--primary" color="black" v-text="msgtexto"></v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="success" text @click="msgbox = false">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout text-xs-center wrap>
      <v-flex xs12>
        <v-form ref="form" v-model="valid">

          <v-row>

            <v-col cols="12" sm="4" md="4">
              <template>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                      <v-text-field
                        :rules="fechaRules"
                        v-model="newAsesoria.fecha"
                        label="Seleccione fecha"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="newAsesoria.fecha"
                    :min="today"
                    @change="save1"
                  ></v-date-picker>
                </v-menu>
              </template>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="newAsesoria.horainicio"
                :items="horas"
                item-value="value"
                label="Selecciona una hora de inicio"
                :rules="horaRules"
              ></v-select>
            </v-col>

             <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="newAsesoria.horafin"
                :items="horas"
                item-value="value"
                label="Selecciona una hora de fin"
                :rules="horaRules"
              ></v-select>
            </v-col>

          </v-row>

          <v-row>
             <v-col cols="12" sm="4" md="4">
              <v-select
                v-model="newAsesoria.idcurso"
                :items="cursos_profesor"
                item-value="value"
                label="Seleccionar Curso"
                :rules="horaRules"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                label="Temas"
                persistent-hint
                v-model="newAsesoria.descripcion"
                hint="Temas a tratar, escribirlos como lista"
                required
                auto-grow
                outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-btn :disabled="!valid" color="success" class="mr-4 mb-4" @click="validate">Crear Taller</v-btn>
            <v-btn color="warning" class="mr-4 mb-4" :to="{name:'profesorinicio'}">Regresar</v-btn>
          </v-row>

        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
import { axiosInstance } from "../../service/index";
import {mapState, mapMutations} from 'vuex'
export default {
  data () {
    return {
      msgbox: false,
      msgtitulo: '',
      msgtexto: '',
      loading: false,
      menu1: false,

      menu2: false,
      cursos_profesor: [],
      today: new Date().toISOString().substr(0, 10),
      newAsesoria:{
        descripcion: '',
        fecha: '',
        horainicio: '',
        horafin: '',
        idcurso: 0
      },

      valid: false,
      horas:[
        {text:'7 am',value:'07:00:00'},
        {text:'8 am',value:'08:00:00'},
        {text:'9 am',value:'09:00:00'},
        {text:'10 am',value:'10:00:00'},
        {text:'11 am',value:'11:00:00'},
        {text:'12 pm',value:'12:00:00'},
        {text:'1 pm',value:'13:00:00'},
        {text:'2 pm',value:'14:00:00'},
        {text:'3 pm',value:'15:00:00'},
        {text:'4 pm',value:'16:00:00'},
        {text:'5 pm',value:'17:00:00'},
        {text:'6 pm',value:'18:00:00'},
        {text:'7 pm',value:'19:00:00'},
        {text:'8 pm',value:'20:00:00'},
        {text:'9 pm',value:'21:00:00'},
      ],
      horaRules:[
        v => !!v || 'Se necesita de la hora'
      ],
      fechaRules:[
        v => !!v || 'Se necesita de la fecha'
      ]
    }
  },
  computed:{
    ...mapState([
      //'newAsesoria',
      'userData',
      'userAlumno',
      'currentJWT',
      'userProfesor',
      'id_Asesoria',
      'procesoNoTengoCuenta'
      ])
  },
  created(){ 
    this.obtenerCursosProfesor();
  },
  methods:{
    save1 (date) {
      this.$refs.menu1.save(date)
    },
    save2 (date) {
      this.$refs.menu2.save(date)
    },
    validate () {
      this.enviar()
    },
    enviar(){
      const me = this;
      if (parseInt(me.newAsesoria.horainicio)<parseInt(me.newAsesoria.horafin)) {
        let auxURL = '/api/asesoria/postCrearAsesoria?tipo=3' 
        + '&tipo_pago=0' 
        + '&id_Profesor=' + me.currentJWT.id
        + '&id_Curso='  + me.newAsesoria.idcurso 
        + '&id_Alumno=0'
        + '&lugar=por definirse&hora=' + me.newAsesoria.horainicio 
        + '&descripcion='  + me.newAsesoria.descripcion 
        + '&hora_fin='  + me.newAsesoria.horafin 
        + '&fecha=' + me.newAsesoria.fecha 
        + '&token=' + me.currentJWT.token

        //console.log(auxURL)
        axiosInstance.post(auxURL)
          .then(res => {
            const data = res.data;
            if (data.success) {
              this.$refs.form.reset();
              swal("Taller Creado", "Compartir Enlace: " + this.$hostname + "/alumno/unirse_asesoria/"+res.data.id_asesoria_grupal, "success");
            } else {
              swal("Error al crear", data.message, "error");
            }
          }).catch(err =>{
            console.log('Error:')
            console.log(err)
          });
      }else{
         swal("Alerta", "La Hora de Inicio No Puede Ser Mayor a la Hora Final", "warning");
      }
      
    },
    obtenerCursosProfesor(){
      let me = this
      if(me.currentJWT.id){
        let auxURL = '/api/profesor/getCursosByProfesor?idProfesor=' + me.currentJWT.id
        axiosInstance.get(auxURL)
          .then(res => {
            for (var i =0; i< res.data.length; i++){
              me.cursos_profesor.push({text : res.data[i].nombre, value: res.data[i].id })
            }
          }).catch(err =>{
            console.log('Error:')
            console.log(err)
          });
      }
    },
    clear(){
      let me = this.newAsesoria;
      me.fecha = null;
      me.horainicio = null;
      me.horafin = null;
    }
  }
}
</script>
