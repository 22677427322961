import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSession from "vue-session";

Vue.use(VueSession);
Vue.config.productionTip = false;
Vue.prototype.$hostname = 'https://learnit-asesorias.com';
// Vue.prototype.$hostname = 'http://localhost:8080';

import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId: '511412880627-llnp83pj7j7i7ipn2h4dl2lh4hhbj29l.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: true
}
Vue.use(GAuth, gauthOption)

import facebookLogin from 'facebook-login-vuejs';
 
Vue.component('my-component', {
    components: {
        facebookLogin
    }
});

/* import { VFBLogin } from 'facebook-login-vuejs'

Vue.component('my-component', {
  components: {
    VFBLogin
  }
}); */

new Vue({
	router,
	store,
	vuetify,
	icons: {
    iconfont: 'mdiSvg',
  },
	render: (h) => h(App),
}).$mount("#app");
