<template>
  <v-container>

    <v-alert
      v-model="alert"
      border="left"
      close-text="Close Alert"
      color="orange"
      dark
      dismissible
    >{{msgCancelarAsesoria}}</v-alert>

    <v-dialog v-model="msgbox" persistent width="600px">
      <v-card>
        <v-card-title class="headline" v-text="msgtitulo"></v-card-title>
        <v-card-text class="text--primary" color="black" v-text="msgwarn"></v-card-text>
        <v-card-text class="text--primary" color="black" v-text="msgtexto"></v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="success" text @click="cerrar_mensaje">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout text-xs-center wrap>
      <v-row justify="center">
        <!--Usar este Dialog para mostrar los detalles de la asesoria-->
        <!--
          Revisar en vuetify.com el componente (DataTable -> My CRUD)
          para entender el funcionamiento del v-dialog  
        -->
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Mi Asesoria</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <template v-if="detalles.tipo != 0">
                  <div class="d-flex align-center flex-wrap justify-center justify-sm-start mb-4">
                    <v-sheet>
                      <v-icon>{{ icons.mdiShareVariant }}</v-icon>
                      Compartir
                    </v-sheet>
                    <v-sheet class="ma-2">
                      <v-btn
                        icon
                        rounded
                        color="indigo"
                        :href="`https://www.facebook.com/sharer/sharer.php?u=${detalles.link}`"
                        target="_blank"
                      >
                        <v-icon>{{ icons.mdiFacebook }}</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        rounded
                        color="teal"
                        :href="`https://api.whatsapp.com/send?text=Únete a la asesoría en el siguiente enlace: ${detalles.link}`"
                        target="_blank"
                      >
                        <v-icon>{{ icons.mdiWhatsapp }}</v-icon>
                      </v-btn>
                    </v-sheet>
                  </div>
                </template>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field readonly v-model="detalles.curso" label="Curso"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field readonly v-model="detalles.fecha" label="Fecha"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field readonly v-model="detalles.hora" label="Hora Inicio"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field readonly v-model="detalles.hora_fin" label="Hora Fin"></v-text-field>
                  </v-col>
                  <v-col  v-if="detalles.tipo != 0" cols="12" sm="6" md="12">
                    <v-text-field  readonly label="Link para compartir"  v-model="detalles.link"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field readonly v-model="detalles.lugar" label="Lugar"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field readonly :value="(detalles.tipo == 0 ? 'Individual' : detalles.tipo == 1 ? 'Grupal' : 'Taller')" label="Tipo"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field readonly v-model="detalles.nombre" label="Profesor"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field readonly v-model="detalles.por_cobrar" label="Costo"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field readonly v-model="detalles.tipo_pago" label="Tipo Pago"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field readonly :value="(detalles.estado_pago == true ? 'Pagado' : 'Por Pagar')" label="Estado Pago"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="cancelar_asesoria(detalles)">Cancelar Asesoria</v-btn>
              <v-btn color="success" @click="dialog = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  
        <v-row justify="center">
          <v-col cols="11" sm="6">
            <v-autocomplete
              outlined
              color="#000000"
              background-color="yellow"
              v-model="busqueda_institucion"
              :items="institucionesBusquedaList"
              item-value="id"
              item-text="nombre"
              label="Buscar Institucion"
              hide-no-data
              solo
            ></v-autocomplete>
          </v-col>
          <v-col cols="11" sm="6">
            <v-autocomplete
              outlined
              color="#000000"
              background-color="yellow"
              v-model="busqueda_curso"
              :items="cursosByInstitucion"
              item-value="id"
              item-text="nombre"
              label="Buscar Curso"
              solo
              no-data-text="Buscar Institucion primero"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
  
        <v-tabs grow centered>
          <v-tab class="tus_asesorias">Tus Asesorías Programadas</v-tab>
          <v-tab-item>
            <template v-if="empty">
              <v-col cols="12">
                <v-card color="error" dark>
                  <v-card-title class="headline">No tiene asesorias</v-card-title>
                  <v-card-subtitle>Lo sentimos, pero no se encontraron asesorias programadas para ti. Comienza buscando un Curso para poder programarte asesorias.</v-card-subtitle>
                </v-card>
              </v-col>
            </template>
  
  
  
            <template v-if="!empty">
              <v-card  class="d-inline-block mx-auto tus_asesorias" v-for="(item, i) in asesorias" :key="i">
                <v-container>
                  <v-row justify="space-between">
                    <!--v-col cols="auto">
                      <v-img
                        height="200"
                        width="200"
                        src="https://cdn.vuetifyjs.com/images/cards/halcyon.png"
                      ></v-img>
                    </v-col-->
                    <v-col dense class="text-center p-30">
                      <v-row
                        dense
                        class="flex-column ma-0 fill-height"
                        justify="center"
                      >
                        <v-text-field
                          dense
                          outlined
                          readonly
                          v-model="item.curso"
                          label="Curso"
                          
                        ></v-text-field>
                        <v-text-field
                          dense
                          outlined
                          readonly
                          label="Profesor"
                          v-model="item.nombre"
                        ></v-text-field>

                        <v-text-field
                          dense
                          outlined
                          readonly
                          label="Fecha"
                          v-model="item.fecha"
                        ></v-text-field>

                        <v-text-field
                          dense
                          outlined
                          readonly
                          label="Hora Inicio"
                          v-model="item.hora"
                        ></v-text-field>

                        <v-text-field
                          dense
                          outlined
                          readonly
                          label="Lugar"
                          v-model="item.lugar"
                        ></v-text-field>
                        <v-btn color="success" @click="ver_detalles(item)">Detalles</v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </template>
  
  
  
          </v-tab-item>
        </v-tabs>
  
  
      </v-row>
    </v-layout>
  </v-container>
</template>
<style>
  .tus_asesorias{
    width: 592px;
    height: 400px;
  }
  @media (max-width: 600px){
    .tus_asesorias{
    width: 350px;
    height: auto;
  }
  }
</style>
<script>
import {mapState, mapMutations} from 'vuex'
import { getAsesoriasByAlumnoFuturo, cancelarAsesoria } from '../../service/alumnoService'
import {
    mdiWhatsapp,
    mdiShareVariant,
    mdiFacebook
} from '@mdi/js'
export default {
  data () {
    return {
      icons: {
        mdiWhatsapp,
        mdiShareVariant,
        mdiFacebook
      },
      dialog: false,
      busqueda_institucion: null,
      busqueda_curso: null,
      cursosByInstitucion: [],
      empty: true,
      msgCancelarAsesoria: '',
      asesorias: [],
      detalles: {
        curso: '',
        estado_pago: false,
        fecha: '',
        hora: '',
        hora_fin: '',
        lugar: '',
        por_cobrar: -1,
        nombre: '',
        tipo: null,
        tipo_pago: '',
        id_asesoria: -1,
        link: '',
      },
      alert: false,
      msgbox: false,
      msgtitulo: '',
      msgwarn: '',
      msgtexto: '',
    }
  },
  computed:{
    ...mapState([
      'institucionesBusquedaList',
      'cursosBusquedaList',
      'newAsesoria',
      'currentJWT'
    ])
  },
  created() {
    let me = this
    if(me.cursosBusquedaList.length == 0 || this.institucionesBusquedaList.length == 0){
      me.getCursosyInstitucionesBusquedaList()
    }
    me.newAsesoria.idInstitucion = -1
    me.newAsesoria.idCurso = -1
    me.getMisAsesorias()
    me.resetNewAsesoria()
    me.$store.state.procesoNoTengoCuenta = false,
    me.$store.state.unirseAsesoria = false,
    me.$store.state.crearAsesoria = false,
    me.saveStateToLocalStorage();
  },
  watch: {
    busqueda_institucion () {
      if(this.busqueda_institucion != null){
        this.filtrarCursosByInstitucion();
      }
    },
    busqueda_curso () {
      if(this.busqueda_curso != null){
        this.setCursoBusqueda(this.busqueda_curso);
        this.$router.push({name:'buscar_curso'});
      }
    }
  },
  methods: {
    ...mapMutations([
      'getCursosyInstitucionesBusquedaList',
      'setCursoBusqueda',
      'resetNewAsesoria',
      'saveStateToLocalStorage'
    ]),

    async getMisAsesorias () {
      var me = this
      var respuesta = await getAsesoriasByAlumnoFuturo(me.currentJWT.id, me.currentJWT.token)
      if(respuesta.operacion == true && respuesta.data != false){
        me.asesorias = respuesta.data
        if(me.asesorias.length > 0){
          me.empty = false
        }
      }else{
        me.$store.state.errobj.errtitulo = respuesta.msgtitulo
        me.$store.state.errobj.errtexto = respuesta.msgtexto
        me.$store.state.errobj.errbox = respuesta.msgbox
      }
    },

    ver_detalles(item){
      let me = this;

      me.detalles.curso = item.curso;
      me.detalles.estado_pago = item.estado_pago;
      me.detalles.fecha = item.fecha;
      me.detalles.hora = item.hora;
      me.detalles.hora_fin = item.hora_fin;
      me.detalles.lugar = item.lugar;
      me.detalles.por_cobrar = item.por_cobrar;
      me.detalles.nombre = item.nombre;
      me.detalles.tipo = item.tipo;
      me.detalles.tipo_pago = item.tipo_pago;
      me.detalles.id_asesoria = item.id_asesoria;
      me.detalles.link = this.$hostname + "/alumno/unirse_asesoria/"+item.id_asesoria;

      me.dialog = true;
    },

    async cancelar_asesoria(item){
      const me = this;
      const today = new Date();
      const asesoriaFechaInicio = new Date(`${item.fecha} ${item.hora}`);
      if (asesoriaFechaInicio >= today) {
        const diffTime = Math.abs(asesoriaFechaInicio - today);
        const diffHrs = (diffTime / (1000 * 60 * 60));
        if (diffHrs > 2) {
          let respuesta = await cancelarAsesoria(item.id_asesoria, me.currentJWT.id, me.currentJWT.token)
          if (respuesta.operacion) {
            if (respuesta.data == 'Hay alumnos en la asesoria') {
              this.msgCancelarAsesoria = 'No se puede cancelar asesoria porque hay otros alumnos';
            } else {
              this.msgCancelarAsesoria = 'Se canceló la asesoría con éxito';
            }
            me.dialog = false;
            me.alert = true;
            this.$router.push({name:'alumnoinicio'});
          } else {
            me.$store.state.errobj.errtitulo = respuesta.msgtitulo;
            me.$store.state.errobj.errtexto = respuesta.msgtexto;
            me.$store.state.errobj.errbox = respuesta.msgbox;
          }
        } else {
          swal("Error", "Lo sentimos, no se puede cancelar la Asesoría faltando menos de 2 horas para que se realice.", "error");
        }
      } else {
        swal("Error", "Lo sentimos, no se puede cancelar la Asesoría porque ya se ha realizado", "error");
      }
    },

    cerrar_mensaje(){
      let me = this;
      me.msgbox = false;
    },

    filtrarCursosByInstitucion(){
      this.cursosByInstitucion = []
      this.cursosBusquedaList.forEach(element => {
        if (element.id_institucion == this.busqueda_institucion) {
          this.cursosByInstitucion.push(element)
        }
      })
    },
  }
}
</script>

