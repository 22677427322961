export function initFacebook() {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "313049725818818", // Debes cambiar esto
        cookie: true, // Esto es importante, no está habilitado por defecto
        version: "v13.0",
      });
    };
}

export function loadFacebookSDK(document, id) {
    var js, fjs = document.getElementsByTagName("script")[0];
    if (document.getElementById(id)) {
      return;
    }
    js = document.createElement("script");
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
}